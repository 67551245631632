
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import Row from '../../simple.vue'
  import { getObjectValueByPath } from '@/utils/vuetify/helpers'

@Component({ components: { Row } })
  export default class List extends Vue {
  @Prop({ type: Array, required: true }) items!: any[];
  @Prop({ type: String, default: 'value' }) itemText!: string;
  @Prop({ type: String, default: 'name' }) itemLabel!: string;
  @Prop({ type: String }) formatter?: string;

  get list () {
    const { items, itemText, itemLabel, formatter } = this

    return items.map(item => ({
      value: item,
      label: getObjectValueByPath(item, itemLabel),
      itemText,
      formatter,
    }))
  }
  }
